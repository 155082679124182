window._ = require('lodash');

try {
    window.bootstrap = require('bootstrap');
    window.$ = window.jQuery = require('jquery');
    window.toastr = require('toastr');
    window.Dropzone = require('dropzone');
    require('datatables.net-bs4');
    require('datatables.net-buttons-bs4');
    require('datatables.net-jqui');
    require('select2');
    require('fullcalendar');
    require('@fullcalendar/core');
    require('@fullcalendar/daygrid');
    require('@fullcalendar/timegrid');
    require('@fullcalendar/list');
    require('@fullcalendar/bootstrap5');
    require('gasparesganga-jquery-loading-overlay');
} catch (e) {
    console.log(e)
}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

